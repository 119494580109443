import Wave from "react-wavify";
import { MenuPartDrank, MenuPartFood } from "./MenuPart";

function Menu() {

    const Koffie: { [key: string]: string } = {
        "Americano": "2,5",
        "Espresso": "2",
        "Cortado": "2,5",
        "Cappuccino": "3",
        "Flat white": "3,5",
        "Babyccino (opgeschuimde melk met kaneel of cacao)": "2,5",
        "Latte": "3,5",
        "Huisbereide Chai Latte": "4",
        "Matcha Latte": "4,5",

    };

    const Chocolademelk: { [key: string]: string } = {
        "Klein": "2,5",
        "Groot": "4",
    };
    const ExtrasKoffie: { [key: string]: string } = {
        "Espresso shot": "0,5",
        "Slagroom": "0,5",
        "Iced": "0,5",
        'Ambachtelijke siroop vanille, speculoos, caramel en hazelnoot': "0,5",
    };

    const Thee: { [key: string]: string } = {
        "Zwarte thee - alishan black": "4,5/6,5",
        "Groene thee - green wild van Thaiwan": "4,5/6,5",
        "Witte thee - ruby": "4,5/6,5",
        "Groene Jasmijn parels": "4,5/6,5",
        "Verse muntthee": "4,5/6,5",
    };

    const Infusie: { [key: string]: string } = {
        "Ginger lemon": "4/6",
        "Berry pomegranate blend": "4/6",
        "Kamille thee": "4/6",
    };

    const KoudeDranken: { [key: string]: string } = {
        "Glas water plat of bruis ": "2,5",
        "0,5L water plat of bruis": "4",
        "Glas Homemade ice-tea": "4",
        "1L kan Homemade ice-tea": "10",
        "Cola/ Cola Zero": "2,5",

    };

    const Limonades: { [key: string]: string } = {
        "Framboos": "4/10",
        "Gember": "4/10",
    };

    const Filoes: { [key: string]: string } = {
        "Vers geperst appelsiensap": "2,5",
        "100% Gembershot": "4",
        "Toni shot": "4",
        "Emiel": "4,5",
        "Fons": "4,5",
        "suggestiesapje": "4,5",
    };

    const Alcoholische: { [key: string]: string } = {
        "Mimosa": "6",
        "Glas wijn wit/rosé/rood": "4,5",
        "Glas feestelijke bubbels": "5",
        "Maneblusser (gewoon pintje)": "3,5",
        "Boscoli fruitbier": "3,5",
        "Dino (Tripel)": "4,5",
        "Homemade Hugo (cava + vlierbloesem)": "6",
    };

    const NonAlcoholische: { [key: string]: string } = {
        "Alcoholvrije Mimosa": "6",
        "Alcoholvrije Hugo": "6",
        "Kombucha (roasted four seasons oolong)": "5,5",

    };

    const GroteHonger: { [key: string]: string } = {
        "Boke Ei: spiegelei/omelet/roerei (spek of avocado: + €1)": "8,5",
        "Wafeltjes met seizoensfruit en ahornsiroop": "14,5",
        "Verloren brood met seizoensfruit": "13,5",
        "Yoghurt Bowl met fruit en huisgemaakte granola": "8",
        "Smoothiebowl met huisgemaakte granola": "8",

        "Croque Ham-Kaas": "10",
        "Croque Pesto-Tomaat-Mozzarella": "10",
        "Croque Bolognese": "11",
        "Kom “soep” met brood": "7,5",
        "Suggestie van het moment": "",
        "Spaghetti bolo met gemalen kaas”vlees of veggie”": "16",

    };

    const KleineHonger: { [key: string]: string } = {
        "Kommetje yoghurt met fruit ": "6",
        "Kommetje“soep” met brood": "5,5",
        "Wafeltjes met fruit en ahornsiroop": "10",
        "Verloren brood met seizoensfruit en ahornsiroop": "9,5",
        "Kleine spaghetti bolo met gemalen kaas”vlees of veggie”": "11,5",
    };

    const ZoeteZonde: { [key: string]: string } = {
        "Ambachtelijk gebak": "4,5",
        "Pannenkoeken op grootmoederswijze met suiker naar keuze": "6",
        "Pannenkoeken op grootmoederswijze van de chef, seizoensgebonden": "7",

    };

    const WeekendVerwennerij: { [key: string]: string } = {
        "zoet bordje": "16",
        "hartig bordje": "16",
        "Ik kan niet kiezen luxe bord": "17",
    };



    return (
        <div id="menu" className="bg-[#f8f6e3]">
            <div className="bg-[#9a3415]">
                <Wave fill='#f8f6e3'
                    paused={true}
                    style={{ display: 'flex' }}
                    options={{
                        height: 70,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                    }}
                />
            </div>
            <div className="px-4 bg-[#f8f6e3] max-w-6xl m-auto">
                <div className="md:w-1/2 m-auto">
                    <h1 className={"font-black text-5xl text-center pb-10 text-[#9a3415]"}>Menu</h1>
                    <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>Dit is onze standaard menukaart. Er staat nog meer op de kaart als je bij ons komt eten. </p>
                    <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>De keuken sluit alle dagen om 15:00. </p>
                    <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>Take away van bepaalde drankjes en gerechten mogelijk, vraag ernaar. </p>

                </div>
                <div className={"grid md:grid-cols-3 grid-cols-1 gap-10"}>
                    <div className={""}>
                        <MenuPartDrank entry={Koffie} name={"Koffie"}
                            extra={"Keuze uit koemelk, havermelk of erwt barista Tiptoh*"} />
                        <MenuPartDrank entry={Chocolademelk} name={"Chocolademelk"}
                            extra={"Melk, witte of zwarte chocolade"} />
                        <MenuPartDrank entry={ExtrasKoffie} name={"Extra's"} extra={""} />
                        <div className={"mt-10 text-lg text-left text-[#9a3415]"}>
                            *De Tiptoh Barista schuimt mooi op, heeft een lekkere en romige afdronk, in balans met de
                            smaak van de koffie. De melk is gemaakt op basis van erwten.
                        </div>
                    </div>
                    <div className={""}>
                        <MenuPartDrank entry={Thee} name={"Thee"} size={"Kop/Theepot"}
                            extra={"Biologische thee zorgvuldig samengesteld door Dorien van Mist"} />
                        <MenuPartDrank entry={Infusie} name={"Infusie"} size={"Kop/Theepot"}
                            extra={"Biologische infusies zorgvuldig samengesteld door Dorien van Mist"} />
                        <MenuPartDrank entry={KoudeDranken} name={"Koude Dranken"} extra={""} />
                        <MenuPartDrank entry={Limonades} size={"Glas/Kan"} name={"Limonades"}
                            extra={"Ambachtelijke Siroop: Al de siropen van BOHO Syrups worden door het BOHO team op ambachtelijke wijze gemaakt."} />

                    </div>
                    <div className={""}>
                        <MenuPartDrank entry={Filoes} name={"Filoes "}
                            extra={"Voedzame sappen van dagverse groenten en fruit, recht van ‘t veld, uit Duffel"} />
                        <MenuPartDrank entry={Alcoholische} name={"Alcoholische dranken"} extra={""} />
                        <MenuPartDrank entry={NonAlcoholische} name={"Non-Alcoholische dranken"} extra={""} />
                    </div>
                </div>
                <div className={"grid grid-cols-1 gap-10"}>
                    <div className={""}>
                        <MenuPartFood entry={GroteHonger} extra={"Gerechten zijn ook glutenvrij verkrijgbaar."} name={"Grote honger"} />
                        <MenuPartFood entry={KleineHonger} extra={"Gerechten zijn ook glutenvrij verkrijgbaar."} name={"Kleine honger"} />
                        <MenuPartFood entry={WeekendVerwennerij} extra={"Gerechten zijn ook glutenvrij verkrijgbaar."} name={"Honger (opening-15u)"} />
                        <MenuPartFood entry={ZoeteZonde} extra={"Pannenkoeken worden elke dag huisgemaakt en zijn verkrijgbaar tussen 14u en 16u."} name={"Zoete zonde"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;