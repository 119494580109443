// import React, { useState } from "react";


import Wave from "react-wavify";
import stock5 from "./../../assets/BarElvire23-min.jpg"
import stock7 from "./../../assets/BarElvire93-min.jpg"
import stock8 from "./../../assets/BarElvire96-min.jpg"



const Over: React.FC = () => {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div id="over" className="bg-background">
            <Wave fill='#9a3415'
                  paused={true}
                  style={{display: 'flex'}}
                  options={{
                      height: 70,
                      amplitude: 50,
                      speed: 0.15,
                      points: 3
                  }}
            />
            <div className=" px-4 bg-[#9a3415]">
                <div className={"max-w-6xl mx-auto"}>
                    <div className="md:w-1/2 m-auto">
                        <h1 className={"font-black text-5xl text-center pb-10 text-background"}>Over ons</h1>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4 ">
                        <img src={stock5} alt="stock1" className={"rounded-xl"}/>
                        <div
                            className="font-normal md:text-xl text-md text-left text-background md:col-span-2 m-auto">Bar
                            Elvire, in het hartje van Rijmenam, is een brunchbar waar “lokaal” en “inclusie” centraal
                            staan.<br/>
                            Wij zijn de oprichters, Veerle en Lien, moeder en dochter. De inspiratie voor de naam vonden
                            we in de persoon van onze geliefde moeder en oma die een erg warme, liefdevolle en sociale
                            vrouw was.
                        </div>
                        <img src={stock7} alt="stock3" className={"rounded-xl md:hidden"}/>
                        <div
                            className="font-normal md:text-xl text-md md:text-right text-background md:col-span-2 m-auto">
                            We kozen ervoor om een VZW op te richten. Vanuit deze vorm, kunnen we immers kwetsbare
                            mensen tewerkstellen en ondersteunen in de verdere ontplooiing van hun leven. Dit
                            kleinschalig project werd mede geïnspireerd door Lien haar ervaring in de sociale economie.
                            In de brunchbar zullen diverse mensen dan ook de kans krijgen om mee te draaien en te
                            proberen, te leren en op hun eigen benen te staan.
                        </div>
                        <img src={stock7} alt="stock3" className={"rounded-xl hidden md:block"}/>
                        <img src={stock8} alt="stock2" className={"rounded-xl"}/>
                        <div
                            className="font-normal md:text-xl text-md text-left text-background md:col-span-2 m-auto">Om
                            dit mooie project te kunnen verwezenlijken én in de juiste, (administratieve) banen te
                            kunnen leiden, zocht Lien hulp.
                            Die vond ze bij haar mama Veerle, een administratieve duizendpoot.
                            Het ideale duo, de ene in de dagdagelijkse werking en de andere voornamelijk op de
                            achtergrond.
                            <br/>
                            <br/>
                            Dit is een heel klein stukje van ons verhaal, kom de rest maar zelf ontdekken in Bar Elvire.

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Over;
