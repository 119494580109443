import React, { useState } from "react";

import logo from "./../../assets/logo.png";

const Navigation: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      };

  return (
    <nav className="bg-background border-b-2 border-[#9a3415]">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          {/* Logo */}
          <div>
            <div className="flex items-center py-1 text-text">
              <img src={logo} alt="logo" className="w-20" />
            </div>
          </div>
          <div className="flex justify-between">
            {/* Primary Nav */}
            <div className="hidden md:flex items-center space-x-1">
              <div onClick={() => scrollToSection("over")} className="py-5 px-3 border-b-2 border-b-transparent font-medium text-[#9a3415] hover:border-b-[#9a3415] text-xl hover:cursor-pointer ease-in-out duration-200">
                Over
              </div>
              <div onClick={() => scrollToSection("menu")} className="py-5 px-3 border-b-2 border-b-transparent font-medium text-[#9a3415] hover:border-b-[#9a3415] text-xl hover:cursor-pointer ease-in-out duration-200">
                Menu
              </div>
              <div onClick={() => scrollToSection("reserveer")} className="py-5 px-3 border-b-2 border-b-transparent font-medium text-[#9a3415] hover:border-b-[#9a3415] text-xl hover:cursor-pointer ease-in-out duration-200">
                Reserveer
              </div>
              <div onClick={() => scrollToSection("contact")} className="py-5 px-3 border-b-2 border-b-transparent font-medium text-[#9a3415] hover:border-b-[#9a3415] text-xl hover:cursor-pointer ease-in-out duration-200">
                Contact
              </div>
            </div>
          </div>
          {/* Mobile button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="mobile-menu-button"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"} text-center text-md `}>
        <div onClick={() => scrollToSection("home")} className="block py-2 px-4 font-medium text-[#9a3415]">
          Home
        </div>
        <div onClick={() => scrollToSection("menu")} className="block py-2 px-4 font-medium text-[#9a3415]">
          Menu
        </div>
        <div onClick={() => scrollToSection("over")} className="block py-2 px-4 font-medium text-[#9a3415]">
          Over
        </div>
        <div onClick={() => scrollToSection("contact")} className="block py-2 px-4 font-medium text-[#9a3415]">
          Contact
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
