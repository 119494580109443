import Navigation from "./components/page/Navigation";
import Over from "./components/page/Over";
import Reserveer from "./components/page/Reserveer";
import Contact from "./components/page/Contact";
import Landing from "./components/page/Landing";
import Footer from "./components/page/Footer";
import Menu from "./components/page/Menu";

// import Waiting from "./components/page/Waiting";


function App() {
    return (
        // <Waiting/>
        <div className="bg-background scroll">
            <Navigation/>
            <div className="mx-auto px-4">
                <Landing/>
            </div>
            <Over/>
            <Menu/>
            <Reserveer/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
