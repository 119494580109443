import Wave from "react-wavify";

const Footer: React.FC = () => {
    return (
        <div className={"bg-[#9a3415]"}>
            <div className={"bg-[#d0d4b4]"}>

                <Wave fill='#9a3415'
                      paused={true}
                      style={{display: 'flex'}}
                      options={{
                          height: 70,
                          amplitude: 50,
                          speed: 0.15,
                          points: 3
                      }}
                />
            </div>
            <div className="md:w-1/2 m-auto">
                <h1 className={"font-black text-5xl text-center pb-10 text-background"}>Iedereen welkom en tot snel!</h1>
            </div>
        </div>

    );
};

export default Footer;


