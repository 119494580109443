// import React, { useState } from "react";

import Wave from "react-wavify";
// import ReservationForm from "../../components/page/ReservationForm";

const Reserveer: React.FC = () => {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div id="reserveer" className="bg-[#9a3415]">
            <div>
                <div className="bg-[#f8f6e3]">
                    <Wave fill='#9a3415'
                          paused={true}
                          style={{display: 'flex'}}
                          options={{
                              height: 70,
                              amplitude: 50,
                              speed: 0.15,
                              points: 3
                          }}
                    />
                </div>
                <div className="max-w-6xl md:w-1/2 m-auto">
                    <h1 className={"font-black text-5xl text-center  text-[#f8f6e3]"}>Reserveer</h1>
                    <div
                        className="font-normal md:text-xl text-lg text-center text-background md:col-span-2 m-auto pb-10 p-10">
                        Reserveren via mail, minstens 48u op voorhand en enkel geldig na e-mailbevestiging door ons (dit
                        kan 48u duren)<br/>
                        Stuur ons volgende gegevens om te reserveren:
                            <ul className="my-2 text-center ">
                                <li className="font-medium">Naam</li>
                                <li className="font-medium">Telefoonnummer</li>
                                <li className="font-medium">Datum en uur</li>
                                <li className="font-medium">Aantal personen</li>
                                <li className="font-medium">Eventuele extra opmerkingen</li>
                            </ul>

                        Wij reserveren nooit op het terras, maar als er plaats is mag je daar altijd gaan zitten!

                        <div className={"font-medium mt-4"}>Mailen naar: contact@bar-elvire.be</div>
                    </div>
                    {/*<ReservationForm/>*/}
                </div>
            </div>
        </div>
    );
};

export default Reserveer;