import * as React from "react"

import stock1 from "./../../assets/BarElvire9-min.jpg"
import stock2 from "./../../assets/BarElvire18-min.jpg"
import stock3 from "./../../assets/BarElvire20-min.jpg"
import stock4 from "./../../assets/BarElvire22-min.jpg"
import stock5 from "./../../assets/BarElvire23-min.jpg"
import stock6 from "./../../assets/BarElvire58-min.jpg"
import stock7 from "./../../assets/BarElvire93-min.jpg"
import stock8 from "./../../assets/BarElvire96-min.jpg"


 
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel"
 
export function CarouselDemo() {
  return (
    <Carousel className="max-w-6xl m-auto ">
      <CarouselContent >
      <CarouselItem key={2}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock2} alt="Stock2" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={1}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock1} alt="Stock1" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={3}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock3} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={4}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock8} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={5}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock7} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={6}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock6} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={7}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock5} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem key={8}>
            <div className="p-1">
                <div className="w-full">
                    <img src={stock4} alt="Stock3" className="rounded-xl"/>
                </div>
            </div>
          </CarouselItem>

      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}