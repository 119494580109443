interface MenuPartProps {
    entry: { [key: string]: string };
    name: string;
    extra: string;
    size?: string;
}

export function MenuPartDrank({entry, name, extra, size}: MenuPartProps) {
    return (
        <div className={"mb-10"}>
            <div className={"font-bold text-3xl text-left text-[#9a3415] flex justify-between"}>
                <div className={"flex items-center"}>{name}</div>
                <div className={"flex items-center text-xl"}>{size}</div>
            </div>
            <div className={" text-lg text-left text-[#9a3415]"}>
                {extra}
                {Object.entries(entry).map(([item, price]) => (
                    <div key={item} className={"flex justify-between py-2"}>
                        <div className={"font-medium text-xl text-left text-[#9a3415]"}>{item}</div>
                        <div className={"font-medium text-xl text-right text-[#9a3415] inline-block whitespace-nowrap"}>€ {price}</div>
                    </div>
                ))}
            </div>
        </div>)
}

export function MenuPartFood({entry, name, extra}: MenuPartProps) {
    return (
        <div className={"mb-10"}>
            <div className={"font-bold text-3xl text-left text-[#9a3415] flex justify-between"}>
                <div className={"flex items-center"}>{name}</div>
                <div className={"flex items-center text-xl"}></div>
            </div>
            <div className={" text-lg text-left text-[#9a3415]"}>
                {extra}
                {Object.entries(entry).map(([item, price]) => (
                    <div key={item} className={"flex justify-between py-2"}>
                        <div className={"font-medium text-xl text-left text-[#9a3415]"}>{item}</div>
                        <div className={"font-medium text-xl text-right text-[#9a3415] inline-block whitespace-nowrap"}>€ {price}</div>
                    </div>
                ))}
            </div>
        </div>)
}

