import {CarouselDemo} from "../page/CarouselDemo";

const Landing: React.FC = () => {
    return (
        <div>
            <div className={"md:w-1/2 m-auto"}>
                <h1 className={"font-black text-5xl text-center pt-10 pb-10 text-[#9a3415]"}>Welkom bij Bar Elvire!</h1>
                <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>Stap binnen bij Bar Elvire en laat je betoveren door onze culinaire creaties, waarbij heerlijk eten hand in hand gaat met sociale verandering en gemeenschapszin.</p>
            </div>
            <CarouselDemo/>
        </div>

    );
};

export default Landing;


